import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DownloadIcon from '@mui/icons-material/Download'
import LogoutIcon from '@mui/icons-material/Logout'
import ReplayIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import { Download } from '../api/Download'
import { useActions } from '../hooks/useActions'
import { theme } from '../theme'
import Context from '../utils/context/Context'
import { SessionContext } from './SessionTimeout'

function SaveButton({ isMobile, setLoading, loading }) {
  const buttonLabel = 'save'

  if (isMobile) {
    return (
      <IconButton
        onClick={() => setLoading(buttonLabel)}
        disabled={!!loading?.save}
        sx={{ width: 45, height: 45 }}
      >
        <SaveIcon />
      </IconButton>
    )
  }

  return (
    <LoadingButton
      startIcon={<SaveIcon />}
      onClick={() => setLoading(buttonLabel)}
      loading={!!loading?.[buttonLabel]}
      variant="outlined"
    >
      <span>Save</span>
    </LoadingButton>
  )
}

function LogoutButton({ addToast, setLoading, loading }) {
  const history = useHistory()

  return (
    <LoadingButton
      startIcon={<LogoutIcon />}
      variant="outlined"
      color={'grey'}
      loading={!!loading?.save}
      onClick={async () => {
        await setLoading('logout')
        await Auth.logout({ addToast })

        history.push('/user-logout', {
          message: 'You have logged out. Please login again to restart the questionnaire.',
        })
      }}
    >
      Logout
    </LoadingButton>
  )
}

function PdfButton({ addToast, setLoading, loading }) {
  const buttonLabel = 'pdf'

  return (
    <Button
      startIcon={<DownloadIcon />}
      onClick={async () => {
        setLoading(buttonLabel)
        await Download.pdfDownload({ addToast })
      }}
      disabled={loading ? !!loading[buttonLabel] : false}
    >
      Download PDF
    </Button>
  )
}

function ResetButton({ addToast, loadingControls, loading }) {
  const buttonLabel = 'reset'
  const { resetState } = useActions()

  return (
    <LoadingButton
      color="error"
      startIcon={<ReplayIcon />}
      disabled={!!loading?.reset}
      loading={!!loading?.save}
      variant="outlined"
      onClick={async () => {
        loadingControls(buttonLabel, true)
        await resetState()
        loadingControls(buttonLabel, false)
        addToast(
          'reset',
          'success',
          'Form Responses Reset. You must manually remove patients from the ' +
            'PhenoTips dashboard.',
        )
      }}
    >
      Reset
    </LoadingButton>
  )
}

function Controls({
  demoMode,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning,
  btnLabels,
  loadingControls,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { loading, addToast } = useContext(Context)
  const { activate } = useContext(SessionContext)
  const { sendStateData } = useActions()

  const saveThen = async () => {
    await sendStateData()
    addToast('save', 'success', 'Save completed')
    activate()

    return true
  }
  const setLoading = async (label) => {
    loadingControls(label, true)
    await saveThen()
    loadingControls(label, false)
  }

  return (
    <Box
      sx={{
        backdropFilter: 'blur(10px)',
        bgColor: 'white.transparent',
        boxShadow: 2,
        bottom: '0',
        display: 'flex',
        gap: 2,
        position: 'sticky',
        py: 3,
        px: 10,
        zIndex: '1',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          py: 2,
          px: 2,
          width: '100%',
        },
      }}
      component="footer"
      aria-label="navigation controls"
    >
      <SaveButton
        isMobile={isMobile}
        saveThen={saveThen}
        setLoading={setLoading}
        loading={loading}
      />
      <LogoutButton addToast={addToast} setLoading={setLoading} loading={loading} />
      {demoMode && !isMobile && (
        <>
          <PdfButton addToast={addToast} setLoading={setLoading} loading={loading} />
          <ResetButton addToast={addToast} loadingControls={loadingControls} loading={loading} />
        </>
      )}
      <Box flexGrow={1}></Box>
      <LoadingButton
        variant="outlined"
        onClick={async () => {
          loadingControls('save', true)
          await handleMovePrevious()
          loadingControls('save', false)
        }}
        disabled={!canMovePrevious() || !!loading?.save}
        startIcon={<ArrowBackIcon />}
      >
        {btnLabels[0]}
      </LoadingButton>
      {!isLastStep() ? (
        <LoadingButton
          variant="contained"
          onClick={async () => {
            await saveAndMoveToNext()
          }}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
        >
          <span>{btnLabels[1]}</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          onClick={() => setSubmitWarning(true)}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
          variant="contained"
        >
          <span>{isMobile ? 'Submit' : 'Submit questionnaire'}</span>
        </LoadingButton>
      )}
    </Box>
  )
}

export default Controls
